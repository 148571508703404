<template>
  <b-modal
      id="modal-otp-detail"
      ok-only
      ok-title="OK"
      modal-class="modal-primary"
      no-close-on-backdrop
      centered
      title=""
      header-class="text-primary"
  >
    <b-card-text>
      <b-row class="py-3  align-items-center bg-light">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0"> Token :</label>
        </b-col>
        <b-col sm="9">
          <label class="fw-medium mb-0"> {{otpDetail?.token ? otpDetail?.token : '-'}} </label>
        </b-col>
      </b-row>
      <b-row class="py-3 align-items-center">
        <b-col sm="3" class="text-left text-md-right">
          <label class="fw-medium mb-0"> {{$t('report.campaignList.contactList.cost')}} : </label>
        </b-col>
        <b-col sm="9">
          <label class="fw-medium mb-0 text-primary"> {{otpDetail?.cost ? otpDetail?.cost : '-'}} </label>
        </b-col>
      </b-row>
    </b-card-text>
  </b-modal>
</template>
<script>

export default {
  name: "otpDetail",
  props: {
    otpDetail: Object
  },
  data() {
    return {}
  },
  methods: {
    onComplete() {

    }
  }
}
</script>

<style scoped>

</style>