<template>
  <div v-if="checkPermission('list', 'action', 'otp-reports')">
    <topic-component :topic="$t('SideBarItems.reports.list.otp')"/>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-card no-body class="mb-4">
            <b-row>
              <b-col lg="4" md="6" sm="12">
                <b-form-group label-cols="12"
                              :label="$t('common.dateTime')" label-for="input-sm">
                  <date-time-range-component v-model="time"/>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="4">
                <select-filter-component :label-select="$t('report.otpList.senderName')"
                                         v-model="form.sender_name"
                                         :options="senderNameList"
                                         field-text="text"
                />
              </b-col>
              <b-col lg="3" md="3" sm="4">
                <b-form-group label-cols="12"
                              :label="$t('report.otpList.phone')" label-for="input-sm">
                  <b-form-input v-model="form.phone"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="3" sm="4">
                <select-filter-component :label-select="$t('report.otpList.status')"
                                         v-model="form.send_status"
                                         :options="statusList"
                />
              </b-col>
              <b-col lg="3" md="3" sm="4">
                <b-form-group label-cols="12"
                              :label="$t('report.otpList.refCode')" label-for="input-sm">
                  <b-form-input v-model="form.reference_code"/>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="4">
                <b-form-group label-cols="12"
                              :label="$t('report.otpList.otpCode')" label-for="input-sm">
                  <b-form-input v-model="form.sms_otp"/>
                </b-form-group>
              </b-col>
              <b-col style="margin-top: 35px">
                <button type="button" class="btn waves-effect waves-light btn-gradient" @click="findOTP">{{
                  $t('common.Search')
                  }}
                </button>
                <button type="button" class="btn waves-effect waves-light btn-outline ml-2" @click="resetSearch">
                  {{ $t('common.resetSearch') }}
                </button>
              </b-col>
            </b-row>
            <hr>
            <b-row align-v="center" class="pb-3">
              <b-col>
                <div class="d-flex align-items-center">
                  <span class="mr-3">{{ $t('common.perPage') }}</span>
                  <div>
                    <b-form-select
                        v-model="form.$limit"
                        :options="pageOptions"
                        size="sm"
                        @change="findOTP"
                    />
                  </div>
                  <div class="ml-3">
                    <total-list-component :total="totalAll"/>
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-table
                responsive
                class="mb-0"
                :items="items"
                :fields="fields"
                :busy="isBusy"
                :empty-text="$t('empty')"
                show-empty
            >
              <template #head()="{ label, field: { key, sortable }}">
                {{ $t(label) }}
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong> {{ $t('loading') }}</strong>
                </div>
              </template>
              <template #empty="scope">
                <empty-table/>
              </template>
              <template #cell(createdAt)="data">
                {{ changeDateFormat(data.item.createdAt) }}
              </template>
              <template #cell(valid_period_min)="data">
                <span class="text-bold text-primary">{{ data.item.valid_period_min }}</span>
              </template>
              <template #cell(status)="data">
                <b-badge v-if="data.item.status !=='-'|| !data.item.status" pill :class="colorButton(data.item.status)"
                         class="px-2 pt-1 text-white badge ">
                  {{ filterValue(statusList, data.item.status) }}
                </b-badge>
                <label v-else> {{ data.item.status }}</label>
              </template>
              <template #cell(action)="data">
                <i style="color: #0d73d9" class="action-button icon-eye " @click="viewDetail(data.item)"/>
              </template>
            </b-table>
          </b-card>
        </b-col>
        <div class="ml-auto">
          <b-pagination
              v-model="form.$skip"
              :total-rows="totalRows"
              :per-page="form.$limit"
              align="fill"
              class="my-0"
              size="sm"
              @input="findOTP"
          />
        </div>
      </b-row>
      <otp-detail :otp-detail="otpDetail"/>
    </b-card>
  </div>
</template>

<script>
import DateTimeRangeComponent from "@/components/common/dateTimeRangeComponent";
import TopicComponent from "@/components/common/topicComponent";
import OtpDetail from "@/views/reports/otp/detail";
import moment from "moment";
import reportApi from "@/repository/reportApi";
import functionsCommon from "@/common/functions";
import alert from "@/common/alert";
import senderIdApi from "@/repository/senderIdApi";
import masterData from "@/common/masterData.json";
import SelectFilterComponent from "@/components/common/selectFilterComponent";
import functions from "@/common/functions";
import EmptyTable from "@/components/common/emptyTable.vue";
import TotalListComponent from "@/components/common/totalList.vue";

export default {
  name: "otp",
  components: {
    TotalListComponent,
    EmptyTable,
    SelectFilterComponent,
    OtpDetail,
    TopicComponent,
    DateTimeRangeComponent
  },
  data: () => ({
    totalAll: 0,
    otpDetail: {},
    form: {
      date_from: null,
      date_to: null,
      sms_otp: '',
      reference_code: '',
      phone: '',
      sender_name: null,
      send_status: null,
      $skip: 1,
      $limit: 10,
    },
    isBusy: false,
    time: [
      new Date(moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00'),
      new Date(moment(new Date()).format('YYYY-MM-DD') + 'T23:59:59')
    ],
    fields: [
      {
        key: "createdAt",
        label: "report.otpList.date",
      },
      {
        key: "sender_name",
        label: "report.otpList.senderName",
      },
      {
        key: "sms_otp",
        label: "report.otpList.otpCode",
      },
      {
        key: "valid_period_min",
        label: "report.otpList.validityPeriod",
      },
      {
        key: "status",
        label: "report.otpList.status",
      },
      {
        key: "phone",
        label: "report.otpList.phone",
      },
      {
        key: "reference_code",
        label: "report.otpList.refCode",
      },
      {
        key: "action",
        label: "report.otpList.action",
      },

    ],
    items: [],
    totalRows: 1,
    pageOptions: [10, 15, 50, 100],
    senderNameList: [],
    statusList: masterData.otpStatusList,
  }),
  mounted() {
    this.findOTP()
    this.findSenderId()
  },
  methods: {
    viewDetail(data) {
      this.otpDetail = data
      this.$bvModal.show('modal-otp-detail')
    },
    colorButton(data) {
      switch (data) {
        case 'PROCESSING':
          return 'bg-warning';
        case 'SUCCESS':
          return 'bg-success';
        case 'VERIFIED':
          return 'bg-success';
        case 'EXPIRED':
          return 'bg-danger';
        case 'BLOCKED':
          return 'bg-info';
        case 'UNVERIFIED':
          return 'bg-dark';
        default:
          return
      }
    },
    filterValue(arr, val) {
      return functionsCommon.filterMasterData(arr, val, this.$i18n.locale)
    },
    changeDateFormat(date) {
      return date ? functionsCommon.changeDateFormat(date) : '-'
    },
    resetSearch() {
      this.time = [
        new Date(moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00'),
        new Date(moment(new Date()).format('YYYY-MM-DD') + 'T23:59:59')
      ]
      this.form = {
        date_from: null,
        date_to: null,
        sms_otp: '',
        reference_code: '',
        phone: '',
        sender_name: null,
        send_status: null,
        $skip: 1,
        $limit: 10,
      }
      this.findOTP()
    },
    findOTP() {
      this.isBusy = !this.isBusy
      this.form.date_from = moment(this.time[0]).format('YYYY-MM-DD') + 'T' + moment(this.time[0]).format('HH:mm:ss') + '.000Z'
      this.form.date_to = moment(this.time[1]).format('YYYY-MM-DD') + 'T' + moment(this.time[1]).format('HH:mm:ss') + '.999Z'
      reportApi.getOtpAll(_.cloneDeep(this.form)).then(response => {
        if (response?.codeSYS === '001') {
          this.totalAll = response.total
          this.totalRows = response.total
          this.items = response.data
        }
      }).finally(() => {
        this.isBusy = !this.isBusy
      })
    },
    findSenderId() {
      const data = {
        type: 'otp',
      }
      senderIdApi.getTempAll(data).then(resp => {
        this.senderNameList = resp.data.map(item => {
          return {
            value: item?.sender_id,
            text: item?.sender_id
          }
        })
      })
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    }
  }
};
</script>
<style scoped>
.action-button {
  cursor: pointer;
}
</style>
